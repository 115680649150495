import React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as R from 'ramda'
import { Nil, NumberUtils, Text, Utils } from '@pbt/pbt-ui-components'

import { RetailOrderLineItem } from '~/api/graphql/generated/types'
import DragAndDropTable from '~/components/common/lists/DragAndDropTable'
import {
  getInvoiceGroupSubTotal,
  isGroupedInvoiceItem,
  isRetailOrderLineItem,
} from '~/components/dashboard/invoices/invoiceUtils'
import FeatureToggle from '~/constants/featureToggle'
import {
  FINANCE_TABLE_CELL_HEIGHT,
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
} from '~/constants/financeTable'
import {
  expandGroups,
  getGroupIdIsExpanded,
} from '~/store/duck/clientFinanceData'
import { useIsChewyCheckoutEnabled } from '~/store/hooks/business'
import { getFeatureToggle, getInvoiceStates } from '~/store/reducers/constants'
import {
  getFinanceIsFetching,
  getFinanceIsRearranging,
  getFinanceIsSaving,
} from '~/store/reducers/finance'
import {
  getInvoiceV3,
  getInvoiceV3SubItemById,
} from '~/store/reducers/invoiceV3'
import { getOrdersIsSending } from '~/store/reducers/orders'
import { getSoapId } from '~/store/reducers/soap'
import { InvoiceLineItem } from '~/types'

import ChargesTableItemCells from './cells/FinanceTableItemCells'
import {
  CHARGES_COLUMNS,
  INVOICE_COLUMNS,
  INVOICE_COLUMNS_WITH_REFUND,
} from './Columns'

const useStyles = makeStyles(
  (theme) => ({
    footerCell: {
      padding: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
    },
    selectedRow: {
      outline: `1px solid ${theme.colors.tabSelected}`,
      backgroundColor: `${theme.colors.tableLeftColumnBackground} !important`,
    },
    table: {
      border: 'none',
      backgroundColor: theme.colors.contentBackground,
    },
    tableTitle: {
      height: theme.spacing(4),
      padding: `0 ${theme.spacing(0, 1)}`,
      borderBottom: 'none',
      '&:first-of-type': {
        paddingLeft: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE + 1.5),
      },
      '&:last-of-type': {
        textAlign: 'right',
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
    tableRow: {
      '&:first-of-type': {
        borderTop: theme.constants.tabBorder,
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'inherit',
      },
      '&:nth-of-type(odd)': {
        backgroundColor: theme.colors.tableEvenItem,
      },
      '&:last-of-type': {
        borderBottom: theme.constants.tabBorder,
      },
    },
    tableCell: {
      height: FINANCE_TABLE_CELL_HEIGHT,
      '&:first-of-type': {
        paddingLeft: ({ isInvoiceCVCLayout }: UseStylesProps) =>
          theme.spacing(
            isInvoiceCVCLayout ? 2 : FINANCE_TABLE_PADDING_X_SPACING_VALUE,
          ),
      },
      '&:last-of-type': {
        paddingRight: theme.spacing(FINANCE_TABLE_PADDING_X_SPACING_VALUE),
      },
    },
  }),
  { name: 'ChargesTable' },
)

export interface ChargesTableProps {
  Footer?: React.JSXElementConstructor<any>
  SubHeaderComponent?: React.JSXElementConstructor<any>
  chargesId: string | Nil
  currentItemId: string
  dragHandleProps?: DraggableProvidedDragHandleProps | Nil
  hasHeader?: boolean
  hideFooter?: boolean
  invoiceId?: string
  isInvoice?: boolean
  items: (InvoiceLineItem | RetailOrderLineItem)[]
  onHeaderClick?: (currentItemId?: string) => void
  onItemOrderChange: (from: number, to: number) => void
  onSelectItem: (item: InvoiceLineItem | RetailOrderLineItem) => void
  selectedItem?: InvoiceLineItem | RetailOrderLineItem
  useIndexAsId?: boolean
}
interface UseStylesProps {
  isInvoiceCVCLayout?: boolean
}

const ChargesTable = ({
  hideFooter = false,
  chargesId,
  items = [],
  hasHeader = true,
  useIndexAsId = false,
  Footer,
  SubHeaderComponent,
  currentItemId,
  dragHandleProps,
  onSelectItem,
  selectedItem,
  isInvoice = false,
  onItemOrderChange,
  invoiceId,
  onHeaderClick,
}: ChargesTableProps) => {
  const isInvoiceCVCLayout = useIsChewyCheckoutEnabled() && isInvoice

  const classes = useStyles({ isInvoiceCVCLayout })
  const { t } = useTranslation('Common')
  const dispatch = useDispatch()

  const isFetching = useSelector(getFinanceIsFetching)
  const isSaving = useSelector(getFinanceIsSaving)
  const isSendingOrders = useSelector(getOrdersIsSending)
  const isRearranging = useSelector(getFinanceIsRearranging)
  const soapId = useSelector(getSoapId)
  const isEditPostedChargesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.EDIT_POSTED_CHARGES),
  )
  const invoice = useSelector(getInvoiceV3(invoiceId))
  const InvoiceStates = useSelector(getInvoiceStates)
  const itemSection = useSelector(getInvoiceV3SubItemById(currentItemId))

  const voidedStateId = Utils.findConstantIdByName('Voided', InvoiceStates)
  const isVoidedState = R.prop('stateId', invoice) === voidedStateId
  const isFinalized = Boolean(R.path(['soap', 'finalized'], itemSection))

  const isExpandedSelector = (item: InvoiceLineItem | RetailOrderLineItem) => {
    const isParamRetailOrderLineItem = isRetailOrderLineItem(item)
    return getGroupIdIsExpanded({
      patientId: isParamRetailOrderLineItem ? item.patient.id : item.patientId,
      soapId: isParamRetailOrderLineItem ? item.soap?.id : item.soapId,
      group: item.id,
    })
  }

  const hasRefunds = !R.isEmpty(invoice?.refunds || []) && !isVoidedState

  const columns = isInvoice
    ? hasRefunds
      ? INVOICE_COLUMNS_WITH_REFUND
      : INVOICE_COLUMNS
    : CHARGES_COLUMNS

  const isLoading = isFetching || isSaving || isSendingOrders

  const getBundleItemId = (item: InvoiceLineItem) =>
    `charges-table-${item.soapId}_${item.group}`
  const getItemId = (item: InvoiceLineItem | RetailOrderLineItem) =>
    `charges-table-${item.id}_${item.logId}`

  const toggle = (item: InvoiceLineItem | RetailOrderLineItem) => {
    const isItemRetailOrderLineItem = isRetailOrderLineItem(item)

    dispatch(
      expandGroups({
        groupId: {
          patientId: isItemRetailOrderLineItem
            ? item.patient.id
            : item.patientId,
          soapId: isItemRetailOrderLineItem ? item?.soap?.id : item.soapId,
          group: item.id,
        },
      }),
    )
  }

  const getId = (item: InvoiceLineItem | RetailOrderLineItem) => {
    const isParamRetailOrderLineItem = isRetailOrderLineItem(item)
    return isParamRetailOrderLineItem || !isGroupedInvoiceItem(item)
      ? getItemId(item)
      : getBundleItemId(item)
  }

  const allowSelection = (item: InvoiceLineItem | RetailOrderLineItem) =>
    !isGroupedInvoiceItem(item) &&
    (!isInvoice ||
      (isEditPostedChargesEnabled && !isVoidedState && !isFinalized))

  return (
    <DragAndDropTable
      SubHeaderComponent={SubHeaderComponent}
      classes={{
        table: classes.table,
        tableCell: classes.tableCell,
        tableRow: classes.tableRow,
        tableTitle: classes.tableTitle,
        isSelectedRow: classes.selectedRow,
      }}
      columnWidths={columns.map((column) => column.width || undefined)}
      currentItemId={currentItemId}
      data={items}
      dragHandleProps={dragHandleProps}
      footer={
        hideFooter ? null : items.length > 0 ? (
          Footer && currentItemId ? (
            <Footer
              chargesId={chargesId}
              columnsLength={columns.length}
              currentItemId={currentItemId}
            />
          ) : (
            <TableRow>
              <TableCell
                className={classes.footerCell}
                colSpan={columns.length}
              >
                <Text align="right" fontWeight={500}>
                  {t('Common:TOTAL')}{' '}
                  {NumberUtils.formatMoney(getInvoiceGroupSubTotal(items))}
                </Text>
              </TableCell>
            </TableRow>
          )
        ) : null
      }
      getId={getId}
      headerRow={hasHeader ? columns : []}
      isDragDisabled={isInvoice}
      isDropDisabled={isRearranging || isInvoice}
      isLoading={isLoading}
      loadingItemsLength={items?.length ?? 6}
      selectedItem={selectedItem}
      useIndexAsId={useIndexAsId}
      onHeaderClick={onHeaderClick}
      onOrderChange={onItemOrderChange}
      onRowClick={(item) => {
        if (allowSelection(item)) {
          onSelectItem(item)
        }
      }}
    >
      {({ item, tableCellClassName }) => (
        <ChargesTableItemCells
          showUsedPrepaid
          columnsLength={columns.length}
          currentItemId={currentItemId}
          handleSelectItem={onSelectItem}
          invoiceHasRefunds={hasRefunds}
          invoiceId={invoiceId}
          isExpandedSelector={isExpandedSelector}
          isInvoice={isInvoice}
          isVoidedState={isVoidedState}
          item={item}
          selectedItem={selectedItem}
          soapId={soapId}
          tableCellClassName={tableCellClassName}
          toggleExpand={toggle}
        />
      )}
    </DragAndDropTable>
  )
}

export default ChargesTable
