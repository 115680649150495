import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  AlertIconType,
  ApiError,
  ButtonWithLoader,
  EstimateStatesConstant,
  Field,
  Nil,
  NumberUtils,
  PermissionArea,
  Utils,
} from '@pbt/pbt-ui-components'

import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import apiErrorTypes from '~/constants/apiErrorTypes'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { EstimateState } from '~/constants/invoice'
import PaymentType from '~/constants/paymentTypes'
import {
  clearFinanceError,
  clearLineItemCandidates,
  clearLineItemDeleted,
  signEstimate,
} from '~/store/actions/finance'
import { fetchPayment } from '~/store/actions/payments'
import { fetchRhapsodyPayConfig } from '~/store/duck/rhapsodyPay'
import {
  useDisplayRecordDeposit,
  useGetEstimateState,
} from '~/store/hooks/estimate'
import { useOpenInvoice } from '~/store/hooks/finance'
import { getCRUDByArea, getCurrentBusiness } from '~/store/reducers/auth'
import { getEstimateStates, getFeatureToggle } from '~/store/reducers/constants'
import {
  getFinanceError,
  getFinanceIsEstimateDeleting,
  getFinanceIsFetching,
  getFinanceIsLoading,
  getFinanceValidationErrorType,
} from '~/store/reducers/finance'
import { getPaymentsMap } from '~/store/reducers/payments'
import {
  getSOAPisFetchingSoapOrders,
  getSOAPisLoading,
} from '~/store/reducers/soap'
import { getUser } from '~/store/reducers/users'
import {
  BatchInvoice,
  Estimate,
  ExtendPayment,
  InvoiceOrEstimate,
  SaveInvoice,
} from '~/types'
import {
  detectAPIErrorType,
  getErrorMessage,
  getIsCloneError,
  getIsFinalizedError,
} from '~/utils/errors'
import {
  getDepositPaymentIds,
  getInvoicePaymentIds,
  getValidRefundPaymentForDeposit,
} from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import useCloneEstimate from '../soapV2/estimates/utils/useCloneEstimate'
import ItemsErrorsFormatter from './error-formatters/ItemsErrorsFormatter'
import InvoiceActionsMenu from './InvoiceActionsMenu'
import {
  getIsEstimateAttachedToThisSoap,
  getItemsHaveZeroQuantity,
  getRecordDepositProps,
} from './invoiceUtils'
import { useGetPaymentReversalOptions } from './payment/hooks/useGetPaymentReversalOptions'

const useStyles = makeStyles(
  (theme) => ({
    button: {
      height: 40,
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    iconButton: {
      width: 40,
      minWidth: 40,
    },
    addToAppointmentButton: {
      minWidth: 174,
    },
    cloneButton: {
      minWidth: 120,
    },
    recordDepositButton: {
      minWidth: 139,
    },
    recordPaymentButton: {
      minWidth: 148,
    },
    requestPaymentButton: {
      minWidth: 156,
    },
    saveButton: {
      minWidth: 120,
    },
  }),
  { name: 'EstimatesActions' },
)

const useAlertStyles = makeStyles(() => ({
  paper: {
    minWidth: 450,
  },
}))

const InvoiceErrorFormatters = {
  ITEMS_ERRORS: ItemsErrorsFormatter,
}

export interface EstimatesActionsProps {
  clientId: string | Nil
  estimateStateField: Field
  eventId?: string
  fromTimeline?: boolean
  hasUnsavedChanges: () => boolean
  includeServiceFee: boolean
  invoice: Estimate
  isNew?: boolean
  isPosted: boolean
  newEstimateFlow?: boolean
  onClose?: () => void
  onOk?: (id?: string | Nil) => void
  refetchInvoice: () => void
  resetInvoice: () => void
  save: (invoiceParams?: SaveInvoice) => void
  setAttachingToSoapEstimateId?: (attachingToSoapEstimateId: string) => void
  setSoapToAttachEstimateId?: (soapToAttachEstimateId: string) => void
  showEstimateApprovalAfterCreationOn: (...args: any[]) => void
  soapId: string | Nil
}

export const EstimatesActions = ({
  clientId,
  invoice: invoiceProp,
  soapId,
  includeServiceFee,
  estimateStateField,
  eventId,
  onClose,
  onOk,
  refetchInvoice,
  resetInvoice,
  fromTimeline,
  showEstimateApprovalAfterCreationOn,
  save,
  isPosted,
  hasUnsavedChanges,
  isNew,
  newEstimateFlow,
  setAttachingToSoapEstimateId,
  setSoapToAttachEstimateId,
}: EstimatesActionsProps) => {
  const classes = useStyles()
  const alertClasses = useAlertStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Invoices', 'Tooltips', 'Payments'])

  const getPaymentReversalOptions = useGetPaymentReversalOptions()
  const isLoadingFinance = useSelector(getFinanceIsLoading)
  const isLoadingEstimateDeleting = useSelector(getFinanceIsEstimateDeleting)
  const isLoading = isLoadingFinance || isLoadingEstimateDeleting
  const business = useSelector(getCurrentBusiness)
  const invoicePermissionsUpdate = useSelector(
    getCRUDByArea(PermissionArea.INVOICE),
  ).update
  const appointmentPermissionsUpdate = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  ).update
  const error = useSelector(getFinanceError) as ApiError | null
  const validationErrorType = useSelector(getFinanceValidationErrorType)
  const client = useSelector(getUser(clientId)) || {}
  const isSoapLoading = useSelector(getSOAPisLoading)
  const isLoadingSoapOrders = useSelector(getSOAPisFetchingSoapOrders)
  const isFetching =
    useSelector(getFinanceIsFetching) || isSoapLoading || isLoadingSoapOrders
  const isIpoM0EstimatesEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_ESTIMATES),
  )

  const { displayRecordDeposit, hasPermission } =
    useDisplayRecordDeposit(invoiceProp)
  const estimateRequiresSignatureForApproval =
    business?.estimateSignatureOnApproval
  const estimateState = useGetEstimateState()(estimateStateField.value)
  const estimateIsSigned = Boolean(invoiceProp?.signerName)
  const validDepositRefundPayment = getValidRefundPaymentForDeposit(
    invoiceProp as InvoiceOrEstimate,
  )
  const { canRefundNonIntegratedPayments, canRefundGoOrPos } =
    getPaymentReversalOptions(
      validDepositRefundPayment ?? ({} as ExtendPayment),
    )

  const SAVE_TOOLTIP_MESSAGE = t('Tooltips:SAVE_WITH_ZERO_QUANTITY')
  const groups = invoiceProp.groups || []
  const shouldRenderRefundButton =
    estimateState.isApproved &&
    validDepositRefundPayment !== undefined &&
    (!invoiceProp?.invoices || invoiceProp?.invoices.length === 0) &&
    isIpoM0EstimatesEnabled &&
    (canRefundGoOrPos || canRefundNonIntegratedPayments)

  useEffect(() => {
    if (invoiceProp.payments) {
      invoiceProp.payments.forEach((payment) => {
        if (payment.paymentType === PaymentType.DEPOSIT) {
          dispatch(fetchPayment(payment.id))
        }
      })
    }
  }, [invoiceProp])

  const paymentsMap = useSelector(getPaymentsMap)
  const depositPaymentIds = getDepositPaymentIds(
    invoiceProp as InvoiceOrEstimate,
  )
  const invoicePaymentIds = depositPaymentIds
    ? getInvoicePaymentIds(depositPaymentIds, paymentsMap)
    : []

  const shouldRenderRefundOnInvoiceButton =
    isIpoM0EstimatesEnabled &&
    estimateState.isApproved &&
    (!invoiceProp?.invoices || invoiceProp?.invoices.length === 0) &&
    invoicePaymentIds.length > 0

  const [openInvoiceDialog] = useDialog(DialogNames.INVOICE)
  const [openPaymentDetailsDialog] = useDialog(DialogNames.PAYMENT_DETAILS)
  const openInvoice = useOpenInvoice(clientId, openInvoiceDialog)
  const handleOpenInvoice = () => {
    if (invoicePaymentIds.length === 1) {
      const item = invoicePaymentIds[0]
      openInvoice({
        chargesEntityType: 'invoice',
        invoiceId: item,
      })
    } else if (invoicePaymentIds.length > 1) {
      openPaymentDetailsDialog({
        clientId,
        paymentId: depositPaymentIds ? depositPaymentIds[0] : undefined,
      })
    }
    if (onClose) onClose()
  }

  const handleSavingAlertClose = () => {
    dispatch(clearFinanceError())
    dispatch(clearLineItemCandidates())
    dispatch(clearLineItemDeleted())
    resetInvoice()
  }

  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openRefundPaymentDialog] = useDialog(DialogNames.REFUND_PAYMENT)
  const [openSaveInvoiceAlert, , isSaveInvoiceAlertOpen] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
    handleSavingAlertClose,
  )
  const [openSignatureDialog] = useDialog(DialogNames.SIGNATURE_DIALOG)
  const [openConfirmCloseDialog, closeConfirmCloseDialog] = useDialog(
    DialogNames.CONFIRM_CLOSE_DIALOG,
  )
  const [openAppointmentsListDialog] = useDialog(
    DialogNames.APPOINTMENTS_LIST_FOR_ESTIMATE,
  )

  const [openEstimateCantBeModifiedDialog] = useConfirmAlert({
    type: ConfirmAlertType.ESTIMATE_CANT_BE_MODIFIED,
  })

  const [onSaveAction, setOnSaveAction] =
    useState<(inv: InvoiceOrEstimate | BatchInvoice) => void>()

  const callbackOnSaveInvoice = useCloseAfterCreation(
    (callback) => callback(),
    getFinanceIsLoading,
  )

  useEffect(() => {
    if (business?.id) {
      dispatch(fetchRhapsodyPayConfig(business.id))
    }
  }, [business?.id])

  const showInvoiceSavingAlert = (err: ApiError | null) => {
    if (!isSaveInvoiceAlertOpen) {
      const data = err?.response?.data as
        | { type: keyof typeof InvoiceErrorFormatters }
        | undefined
      const Formatter = data?.type
        ? InvoiceErrorFormatters[data.type]
        : undefined
      const prop = Formatter ? 'content' : 'message'
      const value = Formatter ? (
        <Formatter error={data} />
      ) : (
        getErrorMessage(err)
      )

      openSaveInvoiceAlert({
        iconType: AlertIconType.WARN,
        [prop]: value,
      })
      refetchInvoice()
    }
  }

  const onSignInvoiceRequested = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    openEstimateCantBeModifiedDialog({
      preventShowAgainCheckBox: true,
      applyCustomMessage: true,
      message: t('Invoices:ESTIMATE_CANT_BE_MODIFIED_SIGNED_DIALOG_MESSAGE'),
      okButtonText: t('Common:CONTINUE_ACTION'),
      cancelButtonText: t('Common:NO_GO_BACK'),
      classes: alertClasses,
      onConfirm: (proceed) => {
        if (proceed) {
          openSignatureDialog({
            initialSignerValue: Utils.getPersonString(client),
            outputFormat: 'png',
            showSkip: !estimateRequiresSignatureForApproval,
            onSign: (signerName: string, signature: string) => {
              showEstimateApprovalAfterCreationOn()
              if (invoice.clientId) {
                dispatch(
                  signEstimate(
                    invoice.id,
                    signature,
                    invoice.clientId,
                    signerName,
                    {
                      fromTimeline,
                    },
                  ),
                )
              }
            },
          })
        }
      },
    })
  }

  useEffect(() => {
    if (error) {
      const isFinalizedError = getIsFinalizedError(
        detectAPIErrorType(error.responseBody),
      )
      const isCloneError = getIsCloneError(detectAPIErrorType(error))

      if (isFinalizedError || isCloneError) {
        return
      }
      showInvoiceSavingAlert(error)
    }
  }, [Boolean(error)])

  useEffect(() => {
    if (validationErrorType) {
      if (validationErrorType === apiErrorTypes.APPOINTMENT_HAS_NO_SOAP) {
        openAlert({
          iconType: AlertIconType.WARN,
          message: t('Invoices:ESTIMATE_APPOINTMENT_HAS_NO_SOAP'),
        })
      }
    }
  }, [Boolean(validationErrorType)])

  useEffect(() => {
    if ((invoiceProp?.id || invoiceProp?.invoices) && onSaveAction) {
      onSaveAction(invoiceProp)
      setOnSaveAction(undefined)
    }
  }, [invoiceProp])

  const openDepositDialogAfterSaving = (
    invoice: InvoiceOrEstimate | BatchInvoice,
  ) => {
    const recordDepositDialogProps = getRecordDepositProps(
      invoice,
      includeServiceFee,
    )
    openAddPaymentDialog({ clientId, ComponentProps: recordDepositDialogProps })
  }

  const saveIfNeeded = (
    handler: (invoice: InvoiceOrEstimate | BatchInvoice) => void,
    invoiceParams?: SaveInvoice,
  ) => {
    if (!isNew) {
      handler(invoiceProp)
    }
    if (hasUnsavedChanges() || isNew) {
      save(invoiceParams)
      setOnSaveAction(R.always(handler))
    }
  }

  const saveAndSign = () => {
    save({
      posted: isPosted,
      preventShowEstimateApprovalDialog: true,
    })
    setOnSaveAction(R.always(onSignInvoiceRequested))
  }

  const saveAndRecordDeposit = () => saveIfNeeded(openDepositDialogAfterSaving)

  const onOpenAppointmentsListRequested = () => {
    openAppointmentsListDialog({
      autoCopyItems: true,
      clientId: invoiceProp.client,
      estimateId: invoiceProp.id,
      patientId: invoiceProp.patient,
      onChangeSoapToAttachEstimateId: setSoapToAttachEstimateId,
      onChangeAttachingToSoapEstimateId: setAttachingToSoapEstimateId,
    })
  }

  const addToAppointment = () => {
    if (newEstimateFlow && setAttachingToSoapEstimateId) {
      setAttachingToSoapEstimateId(invoiceProp.id)
    }
    if (hasUnsavedChanges() && invoicePermissionsUpdate) {
      openConfirmCloseDialog({
        onNotOk: () => {
          onOpenAppointmentsListRequested()
          closeConfirmCloseDialog()
        },
        onOk: () => {
          save()
          callbackOnSaveInvoice(() => {
            onOpenAppointmentsListRequested()
          })
          closeConfirmCloseDialog()
        },
      })
    } else {
      onOpenAppointmentsListRequested()
    }
  }

  const isEstimateLinkedToThisAppointment = getIsEstimateAttachedToThisSoap(
    invoiceProp as Estimate,
    soapId,
  )

  const EstimateStates: EstimateStatesConstant[] =
    useSelector(getEstimateStates)

  const saveDraft = () => {
    const draftStateId = EstimateStates.find(
      (state) => state.name === EstimateState.DRAFT,
    )?.id
    if (!estimateState.isDraft) {
      /**
       * We manually set value vs using estimateStateField.setValue as the API call
       * gets sent before the value is updated when using setValue. Normally, this
       * would be a problem as setting the value manually would not trigger a re-render,
       * however, we have deemed this safe as the API call itself will trigger the re-render
       */
      estimateStateField.value = draftStateId
    }
    save()
  }

  const approveWithoutSigning = () => {
    const approvedStateId = EstimateStates.find(
      (state) => state.name === EstimateState.APPROVED,
    )?.id
    if (estimateState.isDraft) {
      /**
       * We manually set value vs using estimateStateField.setValue as the API call
       * gets sent before the value is updated when using setValue. Normally, this
       * would be a problem as setting the value manually would not trigger a re-render,
       * however, we have deemed this safe as the API call itself will trigger the re-render
       */
      estimateStateField.value = approvedStateId
    }
    openEstimateCantBeModifiedDialog({
      preventShowAgainCheckBox: true,
      applyCustomMessage: true,
      message: t('Invoices:ESTIMATE_CANT_BE_MODIFIED_DIALOG_MESSAGE'),
      okButtonText: t('Common:CONTINUE_ACTION'),
      cancelButtonText: t('Common:NO_GO_BACK'),
      classes: alertClasses,
      onConfirm: (proceed) => {
        if (proceed) {
          save()
        }
      },
    })
  }

  const onDecline = () => {
    const declinedStateId = EstimateStates.find(
      (state) => state.name === EstimateState.DECLINED,
    )?.id
    if (!estimateState.isDeclined) {
      /**
       * We manually set value vs using estimateStateField.setValue as the API call
       * gets sent before the value is updated when using setValue. Normally, this
       * would be a problem as setting the value manually would not trigger a re-render,
       * however, we have deemed this safe as the API call itself will trigger the re-render
       */
      estimateStateField.value = declinedStateId
    }
    openEstimateCantBeModifiedDialog({
      preventShowAgainCheckBox: true,
      applyCustomMessage: true,
      message: t('Invoices:ESTIMATE_CANT_BE_MODIFIED_DIALOG_MESSAGE'),
      okButtonText: t('Common:CONTINUE_ACTION'),
      cancelButtonText: t('Common:NO_GO_BACK'),
      classes: alertClasses,
      onConfirm: (proceed) => {
        if (proceed) {
          save()
        }
      },
    })
  }

  const { onCloneRequested } = useCloneEstimate({
    clientId,
    estimateId: invoiceProp.id,
    onDialogOpen: onClose,
    patientId: invoiceProp.patient,
    soapBusinessId: business?.id,
    soapId,
  })

  return (
    <Grid container item>
      <Grid item xs>
        <Tooltip
          open={getItemsHaveZeroQuantity(groups)}
          title={SAVE_TOOLTIP_MESSAGE}
        >
          <>
            {estimateRequiresSignatureForApproval &&
              (estimateState.isDraft && !estimateIsSigned ? (
                <ButtonWithLoader
                  className={classNames(classes.button, classes.saveButton)}
                  disabled={
                    !invoicePermissionsUpdate ||
                    isFetching ||
                    isLoading ||
                    getItemsHaveZeroQuantity(groups)
                  }
                  loading={isFetching || isLoading}
                  type="submit"
                  onClick={saveAndSign}
                >
                  {t('Invoices:ESTIMATE_SIGN_AND_APPROVE')}
                </ButtonWithLoader>
              ) : (
                <ButtonWithLoader
                  className={classNames(classes.button, classes.saveButton)}
                  disabled={
                    !invoicePermissionsUpdate ||
                    isFetching ||
                    isLoading ||
                    getItemsHaveZeroQuantity(groups)
                  }
                  loading={isFetching || isLoading}
                  type="submit"
                  onClick={() => save()}
                >
                  {t('Common:SAVE_ACTION')}
                </ButtonWithLoader>
              ))}
            {!estimateRequiresSignatureForApproval &&
              (estimateState.isDraft && !estimateIsSigned ? (
                <ButtonWithLoader
                  className={classNames(classes.button, classes.saveButton)}
                  disabled={
                    !invoicePermissionsUpdate ||
                    isFetching ||
                    isLoading ||
                    getItemsHaveZeroQuantity(groups)
                  }
                  loading={isFetching || isLoading}
                  type="submit"
                  onClick={approveWithoutSigning}
                >
                  {t('Common:APPROVE_ACTION')}
                </ButtonWithLoader>
              ) : (
                <ButtonWithLoader
                  className={classNames(classes.button, classes.saveButton)}
                  disabled={
                    !invoicePermissionsUpdate ||
                    isFetching ||
                    isLoading ||
                    getItemsHaveZeroQuantity(groups)
                  }
                  loading={isFetching || isLoading}
                  type="submit"
                  onClick={() => save()}
                >
                  {t('Common:SAVE_ACTION')}
                </ButtonWithLoader>
              ))}
          </>
        </Tooltip>
        {shouldRenderRefundButton && (
          <ButtonWithLoader
            className={classNames(classes.button, classes.saveButton)}
            disabled={
              !invoicePermissionsUpdate ||
              isFetching ||
              isLoading ||
              getItemsHaveZeroQuantity(groups)
            }
            loading={isFetching || isLoading}
            type="submit"
            onClick={() =>
              openAlert({
                cancelButtonText: t('Common:NO_I_DO_NOT_DONE'),
                iconType: AlertIconType.WARN,
                message: t('Dialogs:ESTIMATE_REFUND_DIALOG.BODY', {
                  name: `${validDepositRefundPayment?.paidByPerson?.firstName} ${validDepositRefundPayment?.paidByPerson?.lastName}`,
                  refundAmt: NumberUtils.formatMoney(
                    Math.abs(validDepositRefundPayment?.unappliedAmount ?? 0),
                  ),
                }),
                okButtonText:
                  canRefundGoOrPos && validDepositRefundPayment.posTxId
                    ? t('Dialogs:ESTIMATE_REFUND_DIALOG.SEND_TO_TERMINAL')
                    : t('Common:REFUND'),
                onCancel: () => {
                  closeAlert()
                },
                onOk: () => {
                  if (canRefundNonIntegratedPayments) {
                    openRefundPaymentDialog({
                      clientId,
                      payment: validDepositRefundPayment,
                    })
                  } else if (canRefundGoOrPos) {
                    openAddPaymentDialog({
                      clientId,
                      isRefund: true,
                      payment: validDepositRefundPayment,
                    })
                  }
                  closeAlert()
                },
              })
            }
          >
            {t('Payments:REFUND_DEPOSIT')}
          </ButtonWithLoader>
        )}
        {shouldRenderRefundOnInvoiceButton && (
          <ButtonWithLoader
            className={classNames(classes.button, classes.saveButton)}
            disabled={isFetching || isLoading}
            loading={isFetching || isLoading}
            type="submit"
            onClick={handleOpenInvoice}
          >
            {t('Invoices:ESTIMATE_REFUND_TO_INVOICE_BUTTON')}
          </ButtonWithLoader>
        )}
        {estimateState.isDraft && !estimateIsSigned && (
          <ButtonWithLoader
            className={classNames(classes.button, classes.saveButton)}
            disabled={
              !invoicePermissionsUpdate ||
              isFetching ||
              isLoading ||
              getItemsHaveZeroQuantity(groups)
            }
            loading={isFetching || isLoading}
            type="submit"
            onClick={onDecline}
          >
            {t('Common:DECLINE_ACTION')}
          </ButtonWithLoader>
        )}

        {!estimateIsSigned && estimateState.isDraft && (
          <ButtonWithLoader
            className={classNames(classes.button, classes.saveButton)}
            disabled={!invoicePermissionsUpdate || isFetching || isLoading}
            loading={isFetching || isLoading}
            onClick={saveDraft}
          >
            {t('Common:SAVE_DRAFT_ACTION')}
          </ButtonWithLoader>
        )}

        {estimateState.isApproved && (
          <>
            {((!soapId && !eventId) || !isEstimateLinkedToThisAppointment) && (
              <Tooltip
                disableFocusListener
                disableHoverListener={Boolean(invoiceProp.id)}
                placement="top"
                title={t('Tooltips:SAVE_ESTIMATE_FIRST')}
              >
                <span>
                  <ButtonWithLoader
                    className={classNames(
                      classes.button,
                      classes.addToAppointmentButton,
                    )}
                    disabled={
                      !appointmentPermissionsUpdate ||
                      isFetching ||
                      isLoading ||
                      !invoiceProp.id
                    }
                    loading={isFetching || isLoading}
                    onClick={addToAppointment}
                  >
                    {t('Common:ADD_TO_APPOINTMENT')}
                  </ButtonWithLoader>
                </span>
              </Tooltip>
            )}
            {displayRecordDeposit && (
              <Tooltip
                disableFocusListener
                disableHoverListener={Boolean(invoiceProp.id)}
                placement="top"
                title={t('Tooltips:SAVE_ESTIMATE_FIRST')}
              >
                <span>
                  <ButtonWithLoader
                    className={classNames(
                      classes.button,
                      classes.recordDepositButton,
                    )}
                    disabled={
                      !hasPermission ||
                      !invoiceProp.id ||
                      isFetching ||
                      isLoading
                    }
                    loading={isFetching || isLoading}
                    type="submit"
                    onClick={saveAndRecordDeposit}
                  >
                    {t('Common:RECORD_DEPOSIT_ACTION')}
                  </ButtonWithLoader>
                </span>
              </Tooltip>
            )}
            <ButtonWithLoader
              className={classNames(classes.button, classes.saveButton)}
              color="secondary"
              disabled={
                !invoicePermissionsUpdate ||
                isFetching ||
                isLoading ||
                getItemsHaveZeroQuantity(groups)
              }
              loading={isFetching || isLoading}
              type="submit"
              onClick={onDecline}
            >
              {t('Common:DECLINE_ACTION')}
            </ButtonWithLoader>
          </>
        )}
        {!estimateIsSigned &&
          !estimateState.isDeclined &&
          !estimateState.isDraft &&
          ((estimateRequiresSignatureForApproval && estimateState.isApproved) ||
            !estimateRequiresSignatureForApproval) && (
            <ButtonWithLoader
              className={classes.button}
              disabled={!invoicePermissionsUpdate || isFetching || isLoading}
              loading={isFetching || isLoading}
              type="submit"
              onClick={saveAndSign}
            >
              {t('Common:SIGN_INVOICE_ACTION')}
            </ButtonWithLoader>
          )}
        {estimateState.isExpired && (
          <ButtonWithLoader
            className={classNames(classes.button, classes.cloneButton)}
            disabled={isLoading || isFetching}
            loading={isFetching || isLoading}
            onClick={onCloneRequested}
          >
            {t('Common:CLONE_ACTION')}
          </ButtonWithLoader>
        )}
      </Grid>
      <Grid item>
        <InvoiceActionsMenu
          isEstimate
          addToAppointment={addToAppointment}
          clientId={clientId}
          includeServiceFee={includeServiceFee}
          invoice={invoiceProp}
          saveIfNeeded={saveIfNeeded}
          soapId={soapId}
          onClone={onClose}
          onOk={onOk}
        />
      </Grid>
    </Grid>
  )
}
