import React from 'react'
import { alpha, Box, BoxProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Text } from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    container: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    content: {
      backgroundColor: alpha(theme.colors.primaryText, 0.02),
      borderTop: theme.constants.tableBorder,
    },
  }),
  { name: 'SoapWidget' },
)

export interface SoapWidgetProps {
  ContainerBoxProps?: Omit<BoxProps, 'title'>
  ContentBoxProps?: Omit<BoxProps, 'title'>
  TitleBoxProps?: Omit<BoxProps, 'title'>
  actions?: React.ReactNode
  children: React.ReactNode
  id?: string
  title: string | React.ReactNode
}

const SoapWidget = ({
  ContainerBoxProps,
  ContentBoxProps,
  TitleBoxProps,
  actions,
  id,
  children,
  title,
}: SoapWidgetProps) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.container}
      display="flex"
      flexDirection="column"
      height="fit-content"
      id={id}
      width="100%"
      {...ContainerBoxProps}
    >
      <Box
        alignItems="center"
        display="flex"
        pb={1}
        pt={2}
        px={2}
        width="100%"
        {...TitleBoxProps}
      >
        {React.isValidElement(title) ? (
          title
        ) : (
          <Text lineHeight={1} variant="h4">
            {title}
          </Text>
        )}
        {actions && actions}
      </Box>
      <Box
        className={classes.content}
        display="flex"
        flexDirection="column"
        width="100%"
        {...ContentBoxProps}
      >
        {children}
      </Box>
    </Box>
  )
}

export default SoapWidget
