import { Theme } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import { PaletteColor } from '@pbt/pbt-ui-components'

type TypographyProps = {
  color?: PaletteColor
  ellipsis?: boolean
  /** gutterBottom - spacing between blocks = `paragraph` prop in Figma */
  gutterBottom?: boolean
}

const shouldForwardProp = (props: any) =>
  props !== 'ellipsis' && props !== 'bold'

const getCommonStyles = ({ ellipsis }: TypographyProps) => css`
  font-family: Rubik, sans-serif;
  margin: 0;
  ${ellipsis === true
    ? css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `
    : ''}
`
const getColorStyle = (
  theme: Theme,
  color: TypographyProps['color'],
  defaultColor: PaletteColor,
) => css`
  color: ${color ? theme.colors[color] : theme.colors[defaultColor]};
`

const getGutterBottomStyle = (
  { gutterBottom }: TypographyProps,
  marginBottom: string,
) =>
  gutterBottom === true
    ? css`
        margin-bottom: ${marginBottom};
      `
    : ''

export const StyledH1 = styled('h1', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  line-height: 2.6rem;
  font-size: 2rem;
  font-weight: 500;
  ${({ theme, color }) => getColorStyle(theme, color, 'brandPrimary')}
  ${({ gutterBottom }) => getGutterBottomStyle({ gutterBottom }, '2.4rem')}
`

const StyledH2 = styled('h2', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  line-height: 2.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  ${({ theme, color }) => getColorStyle(theme, color, 'brandSecondary')}
  ${({ gutterBottom }) => getGutterBottomStyle({ gutterBottom }, '1.6rem')}
`

const StyledH3 = styled('h3', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  ${({ theme, color }) => getColorStyle(theme, color, 'brandSecondary')}
  ${({ gutterBottom }) => getGutterBottomStyle({ gutterBottom }, '1.6rem')}
`

const StyledLinkLg = styled('a', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  text-decoration: underline;
  line-height: 1.9rem;
  font-size: 1.6rem;
  font-weight: 500;
  ${({ theme, color }) => getColorStyle(theme, color, 'brandPrimary')}
`

const StyledLinkRegular = styled('a', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  text-decoration: underline;
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 400;
  ${({ theme, color }) => getColorStyle(theme, color, 'grayGray1')}
`

const StyledLinkBold = styled('a', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 500;
  ${({ theme, color }) => getColorStyle(theme, color, 'grayGray1')}
`

const StyledParagraphSm = styled('p', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  line-height: 1.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  ${({ theme, color }) => getColorStyle(theme, color, 'grayGray1')}
  ${({ gutterBottom }) => getGutterBottomStyle({ gutterBottom }, '0.8rem')}
`

const StyledParagraphRegular = styled('p', {
  shouldForwardProp,
})<TypographyProps>`
  ${getCommonStyles};
  line-height: 2.2rem;
  font-size: 1.4rem;
  font-weight: 400;
  ${({ theme, color }) => getColorStyle(theme, color, 'grayGray1')}
  ${({ gutterBottom }) => getGutterBottomStyle({ gutterBottom }, '1.2rem')}
`

const StyledParagraphLg = styled('p', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  line-height: 2.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  ${({ theme, color }) => getColorStyle(theme, color, 'grayGray1')}
  ${({ gutterBottom }) => getGutterBottomStyle({ gutterBottom }, '1.2rem')}
`

const StyledButtonLabel = styled('div', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  ${({ theme, color }) => getColorStyle(theme, color, 'grayWhite')}
`

const StyledLabel = styled('div', { shouldForwardProp })<TypographyProps>`
  ${getCommonStyles};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.2rem;
  ${({ theme, color }) => getColorStyle(theme, color, 'grayGray1')}
  ${({ gutterBottom }) => getGutterBottomStyle({ gutterBottom }, '1.2rem')}
`

export default {
  H1: StyledH1,
  H2: StyledH2,
  H3: StyledH3,
  Link: StyledLinkRegular,
  LinkBold: StyledLinkBold,
  LinkLg: StyledLinkLg,
  ParagraphSm: StyledParagraphSm,
  Paragraph: StyledParagraphRegular,
  ParagraphLg: StyledParagraphLg,
  ButtonLabel: StyledButtonLabel,
  Label: StyledLabel,
}
