import React, { useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, BoxProps, Collapse, css, Divider, styled } from '@mui/material'

import Typography from '../Typography/Typography'

const StyledValue = styled(Typography.Paragraph)`
  margin-left: auto;
`

const StyledTitleValue = styled(Typography.H3)`
  margin-left: auto;
`

const StyledDivider = styled(Divider, {
  shouldForwardProp: (props) => props !== 'border',
})`
  position: absolute;
  top: 0;
  ${({ border }: { border?: BorderProps }) =>
    border
      ? css`
          right: ${border.position};
          border-color: ${border.color};
        `
      : ''}
`

type BorderProps = {
  color?: string
  position: string
}

interface CollapsibleInformationListProps {
  ContainerProps?: BoxProps
  border?: BorderProps
  initiallyOpened?: boolean
  list: { label: string; value: string }[]
  title: string
  titleValue: string
}

export const CollapsibleInformationList = ({
  ContainerProps,
  border,
  initiallyOpened,
  title,
  titleValue,
  list,
}: CollapsibleInformationListProps) => {
  const [open, setOpen] = useState(initiallyOpened)

  const handleOpen = () => setOpen(!open)

  return (
    <Box position="relative" {...ContainerProps}>
      <Collapse in={open}>
        {list.map(({ label, value }) => (
          <Box display="flex" key={label} ml={3}>
            <Typography.Paragraph>{label}</Typography.Paragraph>
            <StyledValue>{value}</StyledValue>
          </Box>
        ))}
      </Collapse>
      <Box display="flex" onClick={handleOpen}>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        <Typography.H3>{title}</Typography.H3>
        <StyledTitleValue>{titleValue}</StyledTitleValue>
      </Box>
      {Boolean(border) && (
        <StyledDivider border={border} orientation="vertical" />
      )}
    </Box>
  )
}
