import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChewySubmark } from '@pbt/pbt-ui-components/src/icons'

import { CollapsibleInformationList } from '~/components/elements/CollapsibleInformationList/CollapsibleInformationList'
import Typography from '~/components/elements/Typography/Typography'
import { RetailOrderStates } from '~/constants/invoice'
import { useGetChewyOrderState } from '~/store/hooks/retailOrderItems'
import { getInvoiceV3Loading } from '~/store/reducers/invoiceV3'

import { ChewyRetailOrderStatusLabel } from '../../invoices/elements/ChewyRetailOrderStatusLabel/ChewyRetailOrderStatusLabel'
import { LineItemComponentsActions } from '../../soap/rail/summary/orders/orderSummaryUtils'
import SoapWidget from '../../soapV2/SoapWidget'
import ChargesPanel from '../components/ChargesPanel'
import InvoiceTableSubHeader from './InvoiceTableSubHeader'

const useStyles = makeStyles(
  (theme) => ({
    chargeInformationPanel: {
      top: theme.mixins.toolbar.minHeight,
      maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    },
  }),
  { name: 'InvoiceChewyItemsSection' },
)

// TODO: M1 - Implement real data
const mockItems = {
  g1mQRZ1K: [
    {
      id: 'lO5eeqBO',
      clientId: 'lV0mddg1',
      creationDate: '2024-10-07T13:38:24.643698Z',
      discountAllowed: false,
      declined: false,
      priceTaxable: false,
      name: 'Outside pharmacy (technical linkage) | Tablet Oral (Written prescription) | [No price units]',
      patientId: 'VDKbyGRO',
      extendedPrice: 0,
      prepaid: false,
      priceUnits: 'Per 1 mL(s)',
      quantity: 1,
      usedQuantity: 1,
      subTotal: 0,
      orderNotes: '',
      stateEntity: {
        id: 'PL1J8Oa3',
        name: 'Ordered',
      },
      logType: 'INVENTORY',
      logId: 'lO5zvMV6',
      updatedAt: '2024-10-07T13:55:06.400898Z',
      discount: 0,
      taxAmount: 0,
      taxed: false,
      priceId: 'ZEd85o7E',
      priceItemId: '9VQRm2L1',
      price: 0,
      refundLineItems: [],
      inventoryControlled: false,
      inventoryCategoryId: 'PL1J8Oa3',
      modificationDate: '2024-10-07T13:55:06.400898Z',
      soapLogModificationDate: '2024-10-07T13:38:22.845796Z',
      totalRefundEligibleQty: 1,
      totalRestockEligibleQty: 1,
      wplanCharged: true,
      variationPackageTypeId: 'PL1J8Oa3',
      variationFormId: 'JXEeyEzg',
      variationPerPackageUnitsId: 'nm1Kg1ZN',
      variationDeliveryMethodId: 'eJ1W5O3P',
      variationPerPackageAmount: 1,
      unitId: 'PL1J8Oa3',
    },
    {
      id: 'LVABBqy1',
      clientId: 'lV0mddg1',
      creationDate: '2024-10-07T13:38:39.781055Z',
      discountAllowed: true,
      declined: false,
      priceTaxable: false,
      name: 'Femoral osteotomy | Femoral osteotomy',
      patientId: 'VDKbyGRO',
      extendedPrice: 3,
      prepaid: false,
      priceUnits: 'Each',
      quantity: 1,
      usedQuantity: 1,
      subTotal: 3,
      stateEntity: {
        id: 'PL1J8Oa3',
        name: 'Ordered',
      },
      logType: 'PROCEDURE',
      logId: 'P1LMAJEA',
      updatedAt: '2024-10-07T13:55:06.337746Z',
      discount: 0,
      taxAmount: 0,
      taxed: false,
      priceId: 'JEXJvwAV',
      priceItemId: '79VQnD1q',
      price: 3,
      procedureId: '79VQnD1q',
      procedureCategoryId: '5lV0dV0p',
      procedureCode: '23101',
      refundLineItems: [],
      modificationDate: '2024-10-07T13:55:06.337746Z',
      soapLogModificationDate: '2024-10-07T13:38:41.445024Z',
      totalRefundEligibleQty: 1,
      totalRestockEligibleQty: 0,
      wplanCharged: true,
      procedureDescription: 'Femoral osteotomy',
      unitId: 'PL1J8Oa3',
    },
  ],
  X1xWK4Vj: [
    {
      id: 'zVDzzRrE',
      clientId: 'lV0mddg1',
      creationDate: '2024-10-07T13:41:32.513189Z',
      discountAllowed: false,
      declined: false,
      eventId: '1K08dMWE',
      priceTaxable: false,
      name: 'Outside pharmacy (technical linkage) | Tablet By mouth (Written prescription) | [No price units]',
      producerId: 'PL1J8Oa3',
      patientId: 'VDKbyGRO',
      extendedPrice: 0,
      prepaid: false,
      priceUnits: 'Per 1 mL(s)',
      quantity: 10,
      subTotal: 0,
      orderNotes: '',
      stateEntity: {
        id: 'PL1J8Oa3',
        name: 'Ordered',
      },
      soapId: 'nVrDgMyE',
      logType: 'PRESCRIPTION',
      logId: 'NEvBJMNE',
      updatedAt: '2024-10-07T13:55:06.251902Z',
      discount: 0,
      taxAmount: 0,
      taxed: false,
      priceId: 'ZEd85o7E',
      priceItemId: '9VQRm2L1',
      price: 0,
      refundLineItems: [],
      inventoryControlled: false,
      inventoryCategoryId: 'PL1J8Oa3',
      modificationDate: '2024-10-07T13:55:06.251902Z',
      soapLogModificationDate: '2024-10-07T13:41:29.854976Z',
      totalRefundEligibleQty: 10,
      totalRestockEligibleQty: 10,
      wplanCharged: true,
      variationPackageTypeId: 'PL1J8Oa3',
      variationFormId: 'JXEeyEzg',
      variationPerPackageUnitsId: 'nm1Kg1ZN',
      variationDeliveryMethodId: 'eJ1W5O3P',
      variationPerPackageAmount: 1,
      unitId: 'PL1J8Oa3',
      prescriptionType: 'IN_HOUSE',
    },
    {
      id: '0OZrrljO',
      clientId: 'lV0mddg1',
      creationDate: '2024-10-07T13:54:03.802468Z',
      discountAllowed: true,
      declined: false,
      eventId: '1K08dMWE',
      priceTaxable: false,
      name: 'Femoral osteotomy | Femoral osteotomy',
      producerId: 'PL1J8Oa3',
      patientId: 'VDKbyGRO',
      extendedPrice: 6,
      prepaid: false,
      priceUnits: 'Each',
      quantity: 2,
      subTotal: 6,
      stateEntity: {
        id: 'PL1J8Oa3',
        name: 'Ordered',
      },
      soapId: 'nVrDgMyE',
      logType: 'PROCEDURE',
      logId: 'JOazdZVp',
      updatedAt: '2024-10-07T13:55:05.642421Z',
      discount: 0,
      taxAmount: 0,
      taxed: false,
      priceId: 'JEXJvwAV',
      priceItemId: '79VQnD1q',
      price: 3,
      procedureId: '79VQnD1q',
      procedureCategoryId: '5lV0dV0p',
      procedureCode: '23101',
      refundLineItems: [],
      modificationDate: '2024-10-07T13:55:05.642421Z',
      soapLogModificationDate: '2024-10-07T13:54:34.627589Z',
      totalRefundEligibleQty: 2,
      totalRestockEligibleQty: 0,
      wplanCharged: true,
      procedureDescription: 'Femoral osteotomy',
      unitId: 'PL1J8Oa3',
    },
  ],
}

export const InvoiceChewyItemsSection = () => {
  const classes = useStyles()
  const { t } = useTranslation(['Common', 'Invoices'])

  const isLoading = useSelector(getInvoiceV3Loading)

  const chewyItems = mockItems as any
  // TODO: M1 - Add correct labels and description based on label
  const statusId = 'PL1J8Oa3' // (Draft)
  // const statusId = '7NOogO4G' // (Order Placed)
  // const statusId = 'l2ObGV4Z' // (Moved to Chewy Cart)
  const orderNumber = '12345678'
  const { currentState, isOrderPlaced } = useGetChewyOrderState(statusId)
  const title = isOrderPlaced
    ? t('Invoices:CHEWY_ITEMS.TITLE_WITH_ORDER', { orderNumber })
    : t('Invoices:CHEWY_ITEMS.TITLE')
  const descriptionMap = {
    [RetailOrderStates.DRAFT]: t('Invoices:CHEWY_ITEMS.DRAFT_INFORMATION'),
    [RetailOrderStates.MOVED_TO_CHEWY_CART]: t(
      'Invoices:CHEWY_ITEMS.CHEWY_CART_INFORMATION',
    ),
    [RetailOrderStates.ORDER_PLACED]: t(
      'Invoices:CHEWY_ITEMS.ORDER_PLACED_INFORMATION',
    ),
  }
  const description = descriptionMap[currentState?.name]

  if (isLoading || !chewyItems) {
    return null
  }

  return (
    <SoapWidget
      ContainerBoxProps={{ mx: 1, my: 1.5, width: 'unset' }}
      TitleBoxProps={{ py: 0.5, px: 1 }}
      actions={<ChewyRetailOrderStatusLabel ml="auto" statusId={statusId} />}
      title={
        <Box width="100%">
          <Box display="flex" gap={1}>
            <ChewySubmark />
            <Typography.H2 color="chewyPrimary">{title}</Typography.H2>
          </Box>
          <Typography.Paragraph sx={{ ml: 4 }}>
            {description}
          </Typography.Paragraph>
        </Box>
      }
    >
      <Box>
        <ChargesPanel
          hideFooter
          isInvoice
          ComponentsActions={LineItemComponentsActions}
          SubHeader={InvoiceTableSubHeader}
          chargesGroupedItems={chewyItems}
          chargesId={null}
          classes={{
            chargeInformationPanel: classes.chargeInformationPanel,
          }}
          hasHeader={false}
        />
        <CollapsibleInformationList
          initiallyOpened
          // width to dynamically align with invoice footer
          ContainerProps={{
            ml: 'auto',
            pr: 2,
            py: 1,
            width: 'calc(23.75% - 6px)',
          }}
          // TODO: M1 - Implement real data
          list={[
            {
              label: t('Common:SUBTOTAL'),
              value: '$10.00',
            },
            {
              label: t('Common:DISCOUNT'),
              value: '$0.00',
            },
            {
              label: t('Common:TAX'),
              value: '$0.00',
            },
          ]}
          title={t('Common:CHEWY_TOTAL')}
          titleValue="$152.47"
        />
      </Box>
    </SoapWidget>
  )
}
